import React, { useState } from "react";
import { withProfile } from "../../../contexts/profileContext";
import axios from "axios";

import successIcon from "../../../assets/images/success.png";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PaymentStatus = ({ validatedProfile }) => {
  const navigate = useNavigate();
  const [paymentStatus, setPaymentStatus] = useState("");



  useEffect(() => {
    const fetchPaymentStatus = async () => {
      const transecId = { transecId: validatedProfile.transecId };
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/CheckStatus`, transecId, { headers: { "Content-Type": "application/json" } });
 
        if (response.status === 200) {
       
          setPaymentStatus(response.data.paymentStatus[0].payment_status);
        }
      } catch (error) {
        alert("An error occurred during payment submission.");
      }
    };
    fetchPaymentStatus();
  }, [validatedProfile.transecId]);

  return (
    <div className="plan_div">
      <div className="plan_div_inner">
        {paymentStatus === "P" && (
          <div className="plan_div_heading">
            <img src={successIcon} alt="success icon" className="card_image" />
            <p className="heading_title">Order Paid</p>
            <button className="home_page_btn" onClick={() => navigate("/")}>
              Home Page
            </button>
          </div>
        )}
        {paymentStatus === "F" && (
          <div className="plan_div_heading">
            <img src={successIcon} alt="success icon" className="card_image" />
            <p className="heading_title">Order Failed</p>
            <button className="home_page_btn" onClick={() => navigate("/")}>
              Home Page
            </button>
          </div>
        )}
        {paymentStatus === "R" && (
          <div className="plan_div_heading">
            <img src={successIcon} alt="success icon" className="card_image" />
            <p className="heading_title">Order Refunded</p>
            <button className="home_page_btn" onClick={() => navigate("/")}>
              Home Page
            </button>
          </div>
        )}
        {paymentStatus === "U" && (
          <div className="plan_div_heading">
            <img src={successIcon} alt="success icon" className="card_image" />
            <p className="heading_title">Order Unpain</p>
            <button className="home_page_btn" onClick={() => navigate("/")}>
              Home Page
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withProfile(PaymentStatus);
