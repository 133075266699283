import React, { useState } from "react";
import { withProfile } from "../../contexts/profileContext";
import axios from "axios";
import { load } from "@cashfreepayments/cashfree-js";
import successIcon from "../../assets/images/success.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PaymentPage = ({ validatedProfile, setValidatedProfile }) => {
  const location = useLocation();
  const redirectUrl = `${window.location.origin}${location.pathname}${location.search}` + "-status";
  const [billingDetails, setBillingDetails] = useState({
    plan: "",
    users: 0,
    company: 0,
    number: validatedProfile.mobile,
    firstName: validatedProfile.name.split(" ")[0] || "",
    lastName: validatedProfile.name.split(" ")[1] || "",
    email: validatedProfile.email,
    discountCode: "",
    total: 0,
    userId: validatedProfile.userid,
    url: redirectUrl,
  });

  const [planPage, setPlanPage] = useState(0);
  const [planCard, setPlanCard] = useState(true);

  const handleChoosePlan = (planValue) => {
    setBillingDetails((prevDetails) => ({ ...prevDetails, plan: planValue }));
    nextPage();
  };
  const nextPage = () => {
    setPlanPage(planPage + 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setBillingDetails((prev) => {
      const updatedDetails = { ...prev, [name]: value };

      if (name === "plan" && value === "10000") {
        updatedDetails.company = 0; // Reset company when plan is 10000
      }

      return updatedDetails;
    });
  };

  const validateForm = () => {
    const { firstName, lastName, email, plan } = billingDetails;
    if (!firstName || !lastName || !email || !plan) {
      alert("Please fill all required fields.");
      return false;
    }
    return true;
  };

  const calculateTotal = () => {
    const planPrice = parseInt(billingDetails.plan, 10);
    const users = parseInt(billingDetails.users, 10) || 0;
    const company = billingDetails.plan !== "10000" ? parseInt(billingDetails.company, 10) || 0 : 0;

    const subtotal = 500 * users;
    const subcompany = 500 * company;
    const discount = billingDetails.discountCode === "SAVE10" ? ((planPrice + subtotal + subcompany) * 10) / 100 : 0;

    const total = planPrice + subtotal + subcompany - discount || 0;
    billingDetails["total"] = total;

    billingDetails["discount"] = discount;
    return { subtotal, subcompany, discount, total };
  };


  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/updatePaymentDetails`, billingDetails, { headers: { "Content-Type": "application/json" } });

        if (response.status === 200) {
       
          setValidatedProfile({ ...validatedProfile, transecId: response.data.insertResponse.results.insertId });
          let cashfree;
          var initializeSDK = async function () {
            cashfree = await load({
              mode: "sandbox",
            });
          };
          await initializeSDK();

          let checkoutOptions = {
            paymentSessionId: response.data.paymentSessionId,
            // redirectTarget: "_blank",
          };

          cashfree.checkout(checkoutOptions);
      
        }
      } catch (error) {
        alert("An error occurred during payment submission.");
      }
    }
  };

  const { subtotal, subcompany, discount, total } = calculateTotal();

  return (
    <>
      <div className="plan_div">
        <div className="plan_div_inner">
          {planPage === 0 && (
            <>
              <div className="plan_div_heading">
                <p className="heading_title">Choose Your Pricing Plan</p>
                {/* <span className="heading_details">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</span> */}
              </div>
              <div className="plan_div_body">
                <div className="plan_div_body_inner">
                  {planCard ? (
                    <>
                      <div className="body_left_card card" onClick={() => setPlanCard(false)}>
                        <p className="card_title">Professional</p>
                        <span className="card_title_sub">Lorem Ipsum</span>
                        <div className="card_feature">
                          <ul>
                            <li>RS 5000</li>
                            <li>Include 3 user registration</li>
                            <li>Include 5 Company Listing</li>
                          </ul>
                        </div>
                        <div className="card_btn_div">
                          <button className="left_card_btn card_btn" onClick={() => handleChoosePlan("5000")}>
                            Choose Plan
                          </button>
                        </div>
                      </div>

                      <div className="body_right_card card" onClick={() => setPlanCard(true)}>
                        <p className="card_title">Community</p>
                        <span className="card_title_sub">Lorem Ipsum</span>
                        <div className="card_feature">
                          <ul>
                            <li>RS 10000</li>
                            <li>Include 3 user registration</li>
                            <li>Unlimited Company Listing</li>
                            <li>Unlimited file upload</li>
                            <li>Unlimited file upload</li>
                          </ul>
                        </div>
                        <div className="card_btn_div">
                          <button className="right_card_btn card_btn" onClick={() => handleChoosePlan("10000")}>
                            Choose Plan
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="body_right_card card" onClick={() => setPlanCard(false)}>
                        <p className="card_title">Professional</p>
                        <span className="card_title_sub">Lorem Ipsum</span>
                        <div className="card_feature">
                          <ul>
                            <li>RS 5000</li>
                            <li>Include 3 user registration</li>
                            <li>Include 5 Company Listing</li>
                            <li>Include 5 Company Listing</li>
                            <li>Include 5 Company Listing</li>
                          </ul>
                        </div>
                        <div className="card_btn_div">
                          <button className="right_card_btn card_btn" onClick={() => handleChoosePlan("5000")}>
                            Choose Plan
                          </button>
                        </div>
                      </div>

                      <div className="body_left_card card " onClick={() => setPlanCard(true)}>
                        <p className="card_title">Community</p>
                        <span className="card_title_sub">Lorem Ipsum</span>
                        <div className="card_feature">
                          <ul>
                            <li>RS 10000</li>
                            <li>Include 3 user registration</li>
                            <li>Unlimited Company Listing</li>
                          </ul>
                        </div>
                        <div className="card_btn_div">
                          <button className="left_card_btn card_btn " onClick={() => handleChoosePlan("10000")}>
                            Choose Plan
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
          {planPage === 1 && (
            <>
              <div className="plan_div_heading">
                <p className="heading_title">Checkout</p>
              </div>
              {/* Body */}
              <div className="body_div_outer">
                <div className="payment_container_div">
                  <div className="body_div">
                    <div className="billing_details_div checkout_card">
                      <p className="card_title">Billing details</p>

                      <div className="input_div">
                        <label>Select Plan</label>
                        <select name="plan" value={billingDetails.plan} onChange={handleInputChange}>
                          <option value="">Select your plan</option>
                          <option value="5000">5000 Rs with 5 companies and 3 users</option>
                          <option value="10000">10000 Rs with unlimited companies and 3 users</option>
                        </select>
                      </div>

                      <div className="input_div_flax">
                        <div className="input_div">
                          <label>Number of Users</label>
                          <input
                            name="users"
                            type="number"
                            value={billingDetails.users}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 0) {
                                handleInputChange(e);
                              }
                            }}
                            placeholder="Enter number of users"
                            min="0"
                          />
                        </div>
                        {billingDetails.plan !== "10000" && (
                          <div className="input_div">
                            <label>Number of Companies</label>
                            <input
                              name="company"
                              type="number"
                              value={billingDetails.company}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value >= 0) {
                                  handleInputChange(e);
                                }
                              }}
                              placeholder="Enter number of companies"
                              min="0"
                            />
                          </div>
                        )}
                      </div>

                      <div className="input_div">
                        <label>Phone Number</label>
                        <input name="number" value={billingDetails.number} onChange={handleInputChange} placeholder="Enter your phone number" />
                      </div>

                      <div className="input_div_flax">
                        <div className="input_div">
                          <label>First Name *</label>
                          <input name="firstName" value={billingDetails.firstName} onChange={handleInputChange} placeholder="Enter your first name" />
                        </div>
                        <div className="input_div">
                          <label>Last Name *</label>
                          <input name="lastName" value={billingDetails.lastName} onChange={handleInputChange} placeholder="Enter your last name" />
                        </div>
                      </div>

                      <div className="input_div">
                        <label>Email Address *</label>
                        <input name="email" type="email" value={billingDetails.email} onChange={handleInputChange} placeholder="Enter your email address" />
                      </div>
                    </div>

                    {/* Order Summary */}
                    <div className="your_order_div checkout_card">
                      <p className="card_title">Your order</p>
                      <div className="product_div">
                        <div className="item_div item_dark_div">
                          <p>Product</p>
                          <p>Subtotal</p>
                        </div>

                        <div className="item_div">
                          <div className="plan_text">
                            <p className="dark_plan_text">{billingDetails.plan === "5000" ? "Diamond" : "Platinum"}</p>
                            <p>{billingDetails.plan === "5000" ? "5 companies and 3 users" : "Unlimited companies and 3 users"}</p>
                          </div>
                          <p>Rs. {Number(billingDetails.plan).toFixed(2)}</p>
                        </div>

                        {/* {subtotal > 0 && ( */}
                        <div className="item_div">
                          <p>Add More {billingDetails.users} User(s)</p>
                          <p>Rs. {subtotal.toFixed(2)}</p>
                        </div>
                        {/* )} */}

                        {/* {subcompany > 0 && ( */}
                        <div className="item_div">
                          <p>Add More {billingDetails.company} company(s)</p>
                          <p>Rs. {subcompany.toFixed(2)}</p>
                        </div>
                        {/* )} */}

                        <div className="item_div">
                          <p>Discount</p>
                          <p>Rs. {discount.toFixed(2)}</p>
                        </div>

                        <div className="item_div item_dark_div">
                          <p>Total</p>
                          <p>Rs. {total.toFixed(2)} </p>
                        </div>

                        <div className="item_btn_div">
                          <input name="discountCode" value={billingDetails.discountCode} onChange={handleInputChange} placeholder="Enter discount code SAVE10" />
                          <button onClick={() => alert("Discount applied if valid")}>Apply</button>
                        </div>
                      </div>

                      <span>By clicking on the "Confirm Order" button, you agree to our Terms and Conditions, Privacy Policy, and Refund Policy.</span>

                      <button className="confirm_order_btn" onClick={handleSubmit}>
                        Confirm Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="footer_div">
                <p>Copyrights © Tax-Link 2024. All rights reserved.</p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default withProfile(PaymentPage);
